import { render, staticRenderFns } from "./Record.vue?vue&type=template&id=28a46254&scoped=true"
import script from "./Record.vue?vue&type=script&lang=js"
export * from "./Record.vue?vue&type=script&lang=js"
import style0 from "./Record.vue?vue&type=style&index=0&id=28a46254&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28a46254",
  null
  
)

export default component.exports